import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Comment from "../../../components/comment"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import SectionSpacing from "../../../components/section-spacing"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import VideoPlayer from "../../../components/video-player"

const Plant = ({ alt, title, description, image, reverse }) => {
  return (
    <Columns
      space={12}
      alignY="center"
      collapse={[true, false]}
      reverse={reverse === true ? [false, true] : false}
    >
      <Column width={["1/2", "1/3"]}>
        <ImageFrame>{image && <Image alt={alt} image={image} />}</ImageFrame>
      </Column>
      <Column>
        <Stack space={4}>
          <Heading as="h2" level={3}>
            {title}
          </Heading>
          <Paragraph>{description}</Paragraph>
        </Stack>
      </Column>
    </Columns>
  )
}

const HoelderlinLiebtPflanzen = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-pflanzen.jpg" }
        ) {
          ...mediumImage
        }
        poster: file(
          relativePath: { eq: "hoelderlin-liebt/poster-duftstation.png" }
        ) {
          ...largeImage
        }
        hoelderlin: file(
          relativePath: { eq: "hoelderlin-liebt/portrait-hoelderlin.jpg" }
        ) {
          ...avatarImage
        }
        birne: file(
          relativePath: { eq: "hoelderlin-liebt/pflanzen/birne.jpg" }
        ) {
          ...mediumImage
        }
        eiche: file(
          relativePath: { eq: "hoelderlin-liebt/pflanzen/eiche.jpg" }
        ) {
          ...mediumImage
        }
        feige: file(
          relativePath: { eq: "hoelderlin-liebt/pflanzen/feige.jpg" }
        ) {
          ...mediumImage
        }
        rose: file(relativePath: { eq: "hoelderlin-liebt/pflanzen/rose.jpg" }) {
          ...mediumImage
        }
        wacholder: file(
          relativePath: { eq: "hoelderlin-liebt/pflanzen/wacholder.jpg" }
        ) {
          ...mediumImage
        }
        zitrone: file(
          relativePath: { eq: "hoelderlin-liebt/pflanzen/zitrone.jpg" }
        ) {
          ...mediumImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt die Pflanzen"
        description="Die letzten 36 Jahre seines Lebens verbringt Hölderlin bei der Familie Zimmer in Tübingen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… die Pflanzen",
                link: "/sonderausstellungen/hoelderlin-liebt/pflanzen",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt die Pflanzen</PageTitle>
          <Grid columns={[1, 2]} alignY="center">
            <Stack>
              <Paragraph dropcap={true}>
                Hölderlin ist sein Leben lang gerne draußen.
                <br />
                Er liebt die Pflanzen. <br />
                Er mag ihren Duft. <br />
                Das merkt man seinen Gedichten an.
                <br />
                Dort kommen viele Pflanzen vor.
              </Paragraph>
              <Stack space={[3, 3, 6]}>
                <Paragraph>Hölderlin sagt:</Paragraph>
                <Comment
                  image={data.hoelderlin.childImageSharp.gatsbyImageData}
                  collapse={[false, true, false]}
                >
                  <Paragraph size={[3, 3, 4]}>
                    Wir Menschen sind ein Teil der Natur. <br />
                    Wie die Tiere und Pflanzen.
                    <br />
                    Durch die Natur ist alles miteinander verbunden.
                  </Paragraph>
                </Comment>
              </Stack>
            </Stack>

            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                alt="Illustration von einem Rosenstrauch mit Dornen und Knospen."
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Stack space={12}>
          <Plant
            image={data.birne.childImageSharp.gatsbyImageData}
            title="Birne"
            alt="Illustration von einer Birne und einem Birnenbaum mit Blüten und Blättern."
            description="Nach gelben Birnen duftet es in Hölderlins Gedicht ›Hälfte des Lebens‹"
          />
          <Plant
            image={data.rose.childImageSharp.gatsbyImageData}
            title="Rose"
            alt="Illustration von einem Rosenstrauch mit Dornen und Knospen."
            description="Wilde Rosen blühen in vielen von Hölderlins Gedichten."
          />
          <Plant
            image={data.eiche.childImageSharp.gatsbyImageData}
            title="Eiche"
            alt="Illustration von Eichenblättern und Eicheln."
            description={
              <span>
                Die hohen Eichbäume faszinieren Hölderlin. <br />
                Sie sind für ihn Vermittler zwischen Himmel und Erde.
              </span>
            }
          />
          <Plant
            image={data.zitrone.childImageSharp.gatsbyImageData}
            title="Zitrone"
            alt="Illustration von einem Zitronenbaum mit Früchten und Blättern."
            description="Den Geruch von Zitronen kennt Hölderlin von seiner Wanderung durch Frankreich."
          />
          <Plant
            image={data.feige.childImageSharp.gatsbyImageData}
            title="Feige"
            alt="Illustration von einem Feigenblatt und einer Feigenfrucht."
            description={
              <span>
                In seinem Gedicht ›Andenken‹ erinnert sich <br />
                Hölderlin an seine Zeit in Frankreich zurück: <br />
                Dort gab es einen Hof mit einem Feigenbaum.
              </span>
            }
          />
          <Plant
            image={data.wacholder.childImageSharp.gatsbyImageData}
            title="Wacholder"
            alt="Illustration von einem Wacholder-Strauch mit runden Früchten und spitzen Blättern."
            description="In Hölderlins Namen ist auch eine Pflanze versteckt: Der Wacholder."
          />
        </Stack>

        <Grid columns={[1, 2]} alignY="center">
          <Paragraph>
            In der Ausstellung im Hölderlinturm können Sie die Pflanzen aus
            Hölderlins Gedichten auch riechen.
          </Paragraph>
          <VideoPlayer
            src="hoelderlin-liebt/duftstation"
            poster={data.poster}
          />
        </Grid>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtPflanzen
